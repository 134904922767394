import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(14),
        height: theme.spacing(14),
        marginLeft: "auto",
        marginRight: "auto",
    }
}));

export default useStyles;