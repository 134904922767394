import Scene3 from "../assets/Scene3.png";
import Shot2 from "../assets/Shot2.png";
import Scene4 from "../assets/Scene4.png";
import Subshot1 from "../assets/Subshot1.png";
import Subshot2 from "../assets/Subshot2.png";
import Classi from "../assets/CLASSI_login.png";
import Classi2 from "../assets/CLASSI_home_compressed.png";
import Classi3 from "../assets/CLASSI_admin.png";
import Covider from "../assets/COVIDER.png";
import Covider2 from "../assets/COVIDER2.png";
import Firepit from "../assets/Firepit.png";
import SalStocks from "../assets/SalStock.PNG";
import Budgeit1 from "../assets/Budgeit1.png";
import Budgeit2 from "../assets/Budgeit2.png";
import Budgeit3 from "../assets/Budgeit3.png";
import ebaySearch from "../assets/eBaySearchClip.gif";
import ebayDetails from "../assets/ebayDetailsClip.gif";
import ebayWishlist from "../assets/ebayWishlistClip.gif"


const projects = [{title:"eBay App", date:"Dec 2023", desc:"An iPhone app where users can browse eBay with a streamlined UI using the eBay API, built with SwiftUI, GCP, and MongoDB",tech:'Swift, SwiftUI, Storyboard, NodeJS, MongoDB, Google Cloud',images:[ebaySearch, ebayDetails, ebayWishlist],longDesc: 'This was the first iPhone app that I built. It puts a different UI over eBay and allows users to have all the same browsing functionality. Users can search for items, filter their search by a variety of options such as condition, shipping, and location, explore items and their details, find similar products, and even add to favorites.\n\n The app itself was built almost entirely with SwiftUI, with a little bit of Storyboards for implementing things like the launch screen. All the data is fetched through a proxy server written with NodeJS and Express that is running on Google Cloud App Engine. This server then leverages a few different APIs, notably the eBay and Google Custom Search API. The favorites list is persisted using MongoDB Atlas, also accessed through the App Engine server. Check out the GIFs below to see some of the functionality!'},
    {title:"Video Indexer", date:"May 2023", desc:"A tool for analyzing video and then creating and navigating an index, built with OpenCV, TensorFlow, and React",tech:'Python, OpenCV, Tensorflow, HTML/CSS/JS, React',images:[Scene3,Shot2,Scene4,Subshot1,Subshot2],longDesc: 'This project is a program that can automatically construct an index/table of contents for a video clip by separating it into logical sets of scenes, shots, and subshots. It then provides a browser based media player to watch the video clip and jump around different scenes/shots/subshots.\n\nThe program uses an ML model to detect shot changes in video, defined as abrupt cuts from one frame to the next. Logical scenes are then found by taking histograms of shot boundaries and doing statistical analysis using the entire clip\'s mean and standard deviation histogram difference values from adjacent frames. Subshots are found by applying masks onto a subtraction of adjacent frames to reduce noise and detect areas of low or high motion. After creating the index of scenes/shots/subshots, a browser player is launched that highlights the current section of the video playing, and can jump between different parts of the index at will. In essence, this tool can create an automated table of contents for any video file in about half the time it would take to just watch it.'},
    {title:"BudgeIt.xyz", date:"April 2023", desc:"A website for tracking expenses across different categories built with React and Supabase",tech:'HTML/CSS/JS, React, Supabase, SQL',images:[Budgeit1, Budgeit2, Budgeit3],longDesc: 'BudgeIt.xyz was born from me not liking any of the options currently available for tracking expenses and budgeting. Each app I tried had problems, either it was paid, flooded with micro-transactions, or the automated expense classifier would completely mis-classify things. In addition, I wanted to have something that would force me to enter an expense manually everytime I bought something, because checking your budget at the end of the month after everything has been spent helps no one. \n\n That all led me to build BudgeIt, where anyone can sign up with an email and password, and is provided with a form field to enter expenses across common categories. A user can then navigate to another page that will show all transactions for a given month grouped by category to see where their money is going. That\'s it! This was built by me, for me, so basically everytime I think of a feature I want, I add it! Feel free to use this app at https://budgeit.xyz/, but just know that while I can\'t see user passwords, I can, at least at the time of writing this, see every expense entered in the DB.'},
    {title:"CLASSI", date:"December 2022", desc:"A full stack application for evaluating startup pitches built with React and Flask, hosted on AWS",tech:'HTML/CSS/JS, React, Python, Express, Flask, AWS, MySQL',images:[Classi,Classi2,Classi3],longDesc:"CLASSI uses an NLP model to classify and evaluate startup pitches based on their target market using Form-10K's of similar companies. This can help startup founders understand whether their pitch captures important aspects of their target industries.\n\nI was part of a team that built the web interface that integrated the NLP model and allowed users to submit their own pitches in csv format. The web portal featured gated registration, with only .edu emails allowed to use the product, and provided fully featured account management with email verification and password reset. Admin functionality was also provided for data analytics. All results are emailed to users through their provided email address. I led the frontend React design and development and also aided with implementing some API endpoints using Flask. A proof of concept for this product is hosted at http://classi-react-app.s3-website-us-west-2.amazonaws.com/ (there is a good chance this link will change in the near future) with the backend hosted on an AWS EC2 instance."},
    {title:"Covider", date:"April 2022", desc:"A contact tracing android app built with Android Studio, Java, and Firebase",tech:'Android Studio, Java, Firebase',images:[Covider,Covider2],longDesc:'Covider is a contact tracing android app designed to provide USC students and Professors a convenient way to view and manage classes and assess potential risk at different areas on campus as a way to mitigate the impacts of the Covid pandemic.\n\nThe app required users to have USC ID\'s and had separate features for Professors. All users are prompted to complete a daily symptom survey once per day and can view a map or list of all buildings at USC. They can then view the risk level of buildings based on reported cases and any requirements to enter. Professors can view class health profiles and switch classes to be online or in person, notifying students. I contributed to all aspects of this app as part of an agile team.'},
    {title:"Firepit", date:"May 2021", desc:"A simple social media site for sharing short-form text and images/gifs, built using React and Firebase",tech:'HTML/CSS/JS, React, Firebase',images:[Firepit],longDesc: 'Firepit is a social media website that allows users to post short-form text along with images, and makes it convenient for users to find relevant memes through the integration of GIPHY\'s API.\n\nAs part of an agile team, I implemented the entire frontend for the website, including screens for login, register, home, user, post, and about. The backend and database was done entirely using Firebase functions and Firestore NoSQL database.'},
    {title:"Sal Stocks", date:"April 2021", desc:"A full stack stock (paper) trading app with live prices built with HTML/CSS/JS and Java", tech:'HTML/CSS/JS, Java, Apache Tomcat, MySQL',images: [SalStocks],longDesc: 'Sal Stocks is a full stack stock trading app that allows users to trade with fake money using live stock prices.\n\nIt was built using vanilla HTML/CSS/JS for the frontend and Java servlets for the backend. The site allowed users to track their balance, search various stock prices, buy and sell publicly listed companies, view their portfolio, and assess their portfolio\'s overall performance. The backend was built to allow for concurrent trades through the use of semaphores as trades can be highly time sensitive.'}]

export default projects;