import React from 'react';
import styles from './Home.module.css'
import {Avatar, List} from "@material-ui/core";
import useStyles from "../JSStyles/useStyles";
import headshot from '../assets/headhsot.jpeg'
import usc from '../assets/USC_Trojans_logo.svg (1).png'
import kwil from '../assets/kwilonly.svg'
import palantir from '../assets/Palantir_Logo.png'
import PositionItem from "../Components/PositionItem";
import {Button} from "@mui/material";
import EastIcon from '@mui/icons-material/East';
import stylesObj from "../JSStyles/stylesObj";
import {expDescriptions} from "../Objects/ExpDescriptions";
import {useNavigate} from "react-router-dom";

function Home() {

    const navigate = useNavigate()
    const classes = useStyles();

    return (
        <div className={styles.wrapper}>
            <Avatar className={classes.avatar} src = {headshot} />
            <h1 className={styles.name}>Thomas Theolier</h1>
            <h2 className={styles.major}>Software Engineer at Palantir</h2>
            <h3 className={styles.classOf}>Developer with experience developing full stack and blockchain applications</h3>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <PositionItem img={palantir} primaryText={"Palantir, Software Engineer"} secondaryText={"Jan 2024-Present"} desc={expDescriptions.palantirFT} delay = {0} current={true}/>
                <PositionItem img={palantir} primaryText={"Palantir, Software Engineer Intern"} secondaryText={"Summer 2023"} desc={expDescriptions.palantir} delay = {0.05}/>
                <PositionItem img={usc} primaryText={"University of Southern California, Course Producer"} secondaryText={"2020-2021, 2022-2023"} desc={expDescriptions.usc} delay = {0.1}/>
                <PositionItem img={kwil} primaryText={"Kwil, Software Developer"} secondaryText={"2021-2022"} desc={expDescriptions.kwil} delay = {.15}/>
            </List>
            <Button sx={stylesObj.projectButton} variant="text" onClick={()=>navigate("projects")} startIcon={<EastIcon />} disableRipple >
                Projects
            </Button>
        </div>
    );
}

export default Home;
