import React from 'react';
import './Projects.css';
import ProjectCard from "../Components/ProjectCard";
import WestIcon from "@mui/icons-material/West";
import {Button} from "@mui/material";
import projects from "../Components/projects";
import stylesObj from "../JSStyles/stylesObj";
import {useNavigate} from "react-router-dom";

function Projects({windowSize}) {

    const navigate = useNavigate();

    return (
        <div className={"wrapper"}>
            <Button sx={stylesObj.homeButton} variant="text" onClick={()=>navigate('/')} startIcon={<WestIcon />} disableRipple >
                Home
            </Button>
            <h1 style={{color:"white"}}>Things I've Made</h1>
            <div className={"cardContainer"}>
                {projects.map((project,index)=>
                    <ProjectCard key={index} delay = {.1 * index} title={project.title} desc={project.desc} longDesc = {project.longDesc} tech ={project.tech} images = {project.images} date={project.date} index={index}/>
                )}
            </div>
            <p style={{color:'white'}}>Click on a card to learn more!</p>
        </div>
    )
}

export default Projects;