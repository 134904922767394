import React, {useEffect} from 'react';
import './Project.css';
import {Button, Typography} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import projects from "../Components/projects";
import {useParams} from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import stylesObj from "../JSStyles/stylesObj";
import {useNavigate} from "react-router-dom";

function Project() {

    const routeParams = useParams();
    console.log(routeParams)
    const index = routeParams.id;
    const navigate = useNavigate();
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    return (
        <div className={"projWrapper"}>
            <Button sx={stylesObj.projectButtonBottomMargin} variant="text" onClick={()=>navigate("/projects")} startIcon={<WestIcon />} disableRipple >
                Projects
            </Button>
            <Typography sx={{color:'white'}} variant="h4" component="div" gutterBottom>
                {projects[index].title}
            </Typography>
            <Typography sx={{color:'white'}} variant="h5" component="div" gutterBottom>
                {projects[index].tech}
            </Typography>
            <Typography variant="body" sx={ {color:'white',textAlign:"left",whiteSpace:'pre-line',marginTop:'20px'}}>
                {projects[index].longDesc}
            </Typography>
            <Swiper
                pagination={{
                    clickable:true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                {projects[index].images.map((image,index)=>
                    <SwiperSlide key={index}><img className={'slideImg'} src={image} alt={''}/></SwiperSlide>
                )}
            </Swiper>
        </div>
    )
}

export default Project;