import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Home from "./Pages/Home";
import Projects from "./Pages/Projects";
import Project from "./Pages/Project";
import FourOhFour from "./Pages/FourOhFour";
// import Sandbox from "./Pages/Sandbox";

const Main = ({windowSize}) => {
    return (
        <Routes>
            {' '}
            {/* The Switch decides which component to show based on the current URL.*/}
            <Route exact path='/' element = {<Home/>}/>
            <Route exact path='/projects' element={<Projects windowSize = {windowSize}/>}/>
            <Route exact path='/project/:id' element={<Project/>}/>
            <Route exact path='*' element={<FourOhFour/>}/>
            {/*<Route exact path='/sandbox' element = {<Sandbox/>}/>*/}
        </Routes>
    );
};

export default Main;