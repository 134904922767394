const stylesObj = {
    projectButtonBottomMargin: {
        "&.MuiButton-text": {
            color: "white"
        },
        marginRight:'auto',
        marginBottom: '20px',
        "&:hover": {backgroundColor: "transparent", }
    },
    homeButton: {
        "&.MuiButton-text": {
            color: "white"
        },
        marginRight:'auto',
        marginLeft: '3.33%',
        "&:hover": {backgroundColor: "transparent", }
    },
    projectButton: {
        "&.MuiButton-text": {
            color: "white"
        },
        marginLeft:'auto',
        "&:hover": {backgroundColor: "transparent", }
    }
}

export default stylesObj