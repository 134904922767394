import React from 'react';
import './Footer.css';
import {IconButton} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";

function Footer({height,windowSize}) {

    return (
        <div className={height + 60 > windowSize ? "footer2" : "footer"}>
            <div className={"iconContainer"}>
                <IconButton href={"https://www.linkedin.com/in/thomas-theolier-939572172/"} target={"_blank"}>
                    <LinkedInIcon className={"iconColor"}/>
                </IconButton>
                <IconButton href={"https://github.com/ttheolier"} target={"_blank"}>
                    <GitHubIcon className={"iconColor middleIconFooter"}/>
                </IconButton>
                <IconButton href={"mailto:thomasmtheolier@gmail.com"}>
                    <EmailIcon className={"iconColor"}/>
                </IconButton>
            </div>
        </div>
    )
}

export default Footer;