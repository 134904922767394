import React from 'react';
import Main from "./Main";
import './App.css'
import Footer from "./Components/Footer";

//main app, just displays Main component which will switch components based on route
function App() {

    //This checks if outermost div is longer or shorter than the devices screen length and adjusts the footer accordingly
    const [windowSize, setWindowSize] = React.useState(window.innerHeight);
    const [height, setHeight] = React.useState(0)
    const ref = React.useRef(null)

    React.useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            setHeight(ref.current.clientHeight)
        });
        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect();
    }, [ref])

    React.useEffect(() => {
        function handleWindowResize() {
            setWindowSize(window.innerHeight);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

  return (
      <div ref={ref} id={'App'} className={"App"}>
          <Main windowSize = {windowSize} />
          <Footer height={height} windowSize={windowSize}/>
      </div>
  );
}

export default App;
