import React from 'react';
import {ListItem, ListItemText} from "@material-ui/core";
import {motion} from "framer-motion";
import {Typography} from "@mui/material";
import "./PositionItem.css"
import AddIcon from "@mui/icons-material/Add"

function PositionItem({img,primaryText,secondaryText,delay,desc,current}) {

    const item = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 100 },
    }

    const variants = {
        open: { opacity: 1, height: "auto", overflowY: "hidden" },
        closed: { opacity: 0, height: 0, overflowY: "hidden" },
    }

    const [open, setOpen] = React.useState(false);

    const handleClick = () => setOpen(!open);

    return (
        <React.Fragment>
            <ListItem component={motion.div} className = {"positionItemWrapper"} onClick={handleClick} initial="hidden" animate="visible" variants={item} transition={{duration:.75,delay:delay}}>
                <div style={{minHeight:40,minWidth:60,display:"flex"}}>
                    <img style = {{ maxHeight:"40px",maxWidth:40,margin:"0 auto"}} src={img} alt = ""/>
                </div>
                <ListItemText primary={primaryText} secondary={secondaryText} />
                <AddIcon sx={{fill:"white"}} />
            </ListItem>
            <motion.div
                initial={{
                    height: 0,
                    opacity: 0,
                    overflowY: "hidden"
                }}
                animate={open ? "open" : "closed"}
                transition={{
                    duration: .5,
                }}
                variants={variants}
            >
                <div className={"expDescBackground"}>
                    <Typography sx={{color:'white'}} variant="h6" component="div" gutterBottom>
                        {current ? "What I Do:" : "What I Did:"}
                    </Typography>
                    <Typography variant="body" sx={ {color:'white', whiteSpace:'pre-line'}}>
                        {desc}
                    </Typography>
                </div>
            </motion.div>
        </React.Fragment>
    )
}

export default PositionItem;