import React from 'react';
import './ProjectCard.css'
import {Box, Card, CardContent, Fade, IconButton, Modal, Typography} from "@mui/material";
import {motion} from "framer-motion";
import CloseIcon from '@mui/icons-material/Close';
import {useMediaQuery} from "react-responsive";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {useNavigate} from "react-router-dom";

function ProjectCard({title,desc,delay,longDesc,tech,images,date,index}) {

    const card = {
        width:'45%',
        marginBottom:'10px',
        backgroundColor:"#3d434f",
        '&:hover': {
            cursor: 'pointer'
        },
    }

    const cardMobile = {
        width:'90%',
        marginBottom:'10px',
        backgroundColor:"#3d434f",
        '&:hover': {
            cursor: 'pointer'
        },
    }

    const item = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
    }

    const navigate = useNavigate();

    const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });
    const isSmallWidth = useMediaQuery({ query: `(max-width: 650px)` });

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleRoute = () => navigate('/project/'+index);

    return (
        <React.Fragment>
            <Card onClick={isMobile? handleRoute : handleOpen} initial="hidden" animate="visible" variants={item} transition={{duration:.75,delay:delay}} raised={true}  sx = {isSmallWidth?cardMobile:card} component={motion.div}>
                <CardContent>
                    <Typography sx={{color:'white',textAlign:'left'}} variant="h5" component="div" >
                        {title}
                    </Typography>
                    <Typography sx={{color:'#d3d3d3',textAlign:'left'}} variant="h8" component="div" gutterBottom>
                        {date}
                    </Typography>
                    <Typography variant="body2" sx={ {color:'white',textAlign:"left"}}>
                        {desc}
                    </Typography>
                </CardContent>
            </Card>
            <Modal sx={{display:'flex',overflowY:'scroll'}} open={open} onClose={handleClose}>
                <Fade in={open}>
                    <Box sx={{outline:'none',padding:'15px',margin:'auto',height:'auto',width:'80%',backgroundColor:'#282c34',display:'flex',flexDirection:'column',borderRadius:'10px'}}>
                        <IconButton sx={{color:'white',marginLeft:'auto'}} onClick={handleClose} aria-label="close" size="large">
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{color:'white'}} variant="h4" component="div" gutterBottom>
                            {title}: {tech}
                        </Typography>
                        <Typography variant="body" sx={ {color:'white',textAlign:"left",whiteSpace:'pre-line'}}>
                            {longDesc}
                        </Typography>
                        <Swiper
                            pagination={{
                                clickable:true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {images.map((image,index)=>
                                <SwiperSlide key={index}><img src={image} alt={''}/></SwiperSlide>
                            )}
                        </Swiper>
                    </Box>
                </Fade>
            </Modal>
        </React.Fragment>
    )
}

export default ProjectCard;