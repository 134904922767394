import React from 'react';

function FourOhFour() {

    return (
        <div>
            <p style={{color:"white"}}>Looks like you tried to access a non-existing route! Click <a style={{color:"blue"}} href={'/'}>Here</a> to get back to Home, or just stay here if you want to!</p>
        </div>
    )
}

export default FourOhFour;